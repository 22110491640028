import Home from 'components/pages/Home';
import Careers from 'components/pages/Careers'
import FreightServices from 'components/pages/FreightServices'
import Cargoes from 'components/pages/Cargoes'
import Shipmanagement from 'components/pages/Shipmanagement'
import AssetManagement from 'components/pages/AssetManagement'
import Integrated from 'components/pages/Integrated'
import OrganizationChart from 'components/pages/OrganizationChart'
import Contact from 'components/pages/Contact'
import Sustainability from 'components/pages/Sustainability'
import Company from 'components/pages/Company'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/Careers" exact component={Careers}/>
        <Route path="/Company"  exact component={Company}/>
        <Route path="/FreightServices"  exact component={FreightServices}/>
        <Route path="/Cargoes"  exact component={Cargoes}/>
        <Route path="/Contact"  exact component={Contact}/>
        <Route path="/Shipmanagement" exact component={Shipmanagement}/>
        <Route path="/AssetManagement"  exact component={AssetManagement}/>
        <Route path="/OrganizationChart"  exact component={OrganizationChart}/>
        <Route path="/Integrated"  exact component={Integrated}/>
        <Route path="/Sustainability" exact component={Sustainability}/>
      </Switch>
    </Router>
  );
}

export default App;
