import { useState, useEffect } from 'react';
import categoryData from 'api/HomeSection'
import Category from 'components/ui/TwoSectionItem'
export default function Categories() {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        // istek
        // setTimeout(() => setCategories(categoryData), 1000)
        setCategories(categoryData)
    }, [])
    return (
        <div className="bg-white ">
            <div className="container mx-auto ">
                <div className="grid grid-cols-1 pt-12">
                    {/* {!categories.length && 'Yükleniyor..'} */}
                    {categories && categories.map((category, index) => <Category key={index} category={category} />)}
                </div>
            </div>
        </div>
    )
}