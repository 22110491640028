export default function SustainabilityCategory({ category: {id, title, image,text,link,focus,target} }) {
    return (
           <div className="container p-0 border rounded">
            <a href={link} className="flex flex-col transition items-left hover:bg-gray-50">
            <img src={image} alt={title} href={link} className="border border-gray-200 rounded" />
            <span className="pl-2 m-0 mt-2 text-lg font-semibold tracking-tight textHeader">{title}</span>
            <span className="pl-2 m-0 mt-2 text-lg  tracking-tight textHeader ">Focus: {focus}</span>
            <span className="pl-2 m-0 mt-2 mb-4 text-lg  tracking-tight textHeader">Target: {target}</span>
            <span className="pb-4 pl-2 pr-2 m-0 text-left textColor m-0tracking-tight text-md">{text}</span>
            </a>
           </div>
    )
}