import React from 'react';
import Header from 'components/Header'
import HeroSection from 'components/HeroSection'
import Footer from 'components/Footer';
import HeaderComp from 'components/ui/HeaderComp';
function OrganizationChart() {
  return (
    <div className='home'>
      <Header/>
      <HeroSection/>
      <HeaderComp
      SectionHeader="Organization Chart"
      />
      <div className="container mx-auto pb-8 pt-4">
      <img src={"./images/svg1.png"}/>
      </div>
      <div className="container mx-auto pb-12 pt-2">
      <img src={"./images/svg2.png"}/>
      </div>
      <Footer/>
    </div>
  );
}

export default OrganizationChart;
