import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps"

function Map(){
  return <GoogleMap defaultZoom={10} defaultCenter={{lat:25.258980, lng:55.326279}} />;
}
const WrappedMap=withScriptjs(withGoogleMap(Map));
export default function ContactMap(){
  return (
 <div>
   <div class="mapouter">
     <div class="gmap_canvas">
       <iframe width="700" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=CLUSTER%20F%20DUBAI&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
       <a href="https://fmovies-online.net"></a>
       <a href="https://www.embedgooglemap.net"></a>
       </div>
       </div>
 </div>
  );
}