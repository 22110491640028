import React from 'react';
import Header from 'components/Header'
import HeroSection from 'components/HeroSection'
import Services from 'components/Services'
import ServicesItem from 'components/ServicesItem'
import LatestNews from 'components/LatestNews'
import Footer from 'components/Footer'
import HeaderComp from 'components/ui/HeaderComp'
import HomeTwoSectionss from 'components/HomeTwoSectionss'
function Company() {
  return (
    <div className='home'>
      <Header/>
      <HeroSection/>
      <HeaderComp
      SectionHeader="About us"
      text1="Densay is an integrated shipping group that plays a crucial role in the global commodity supply chain. We leverage our experience, data, and intelligence to deliver reliable, safe, and efficient maritime solutions to our customers in an increasingly data-driven and digitized world."
      text2="We transport a wide range of commodities, serving grain houses, energy companies, traders, miners, steel mills, industrials, and utility providers. We manage ships that carry essential commodities across the oceans, helping bring food from farm to refrigerator. We enable global trade by investing in supply chains and crucial assets."
      text3="Global trade drives economic growth and development. Our mission is to help facilitate global trade. Our vision is to transform it through innovation and digitization, making it more efficient, responsible, and sustainable."
      />
      <HeaderComp
      SectionHeader="Scope of Ship Management"
      text1="While determining the scope of Densay Environmental and Integrated Management System, it is defined in its system in a way to cover all functions, product and services-related effects, physical location and neighbors that will be under its influence. It monitors, measures and evaluates the natural resource consumption and outputs originating from its activities in all seas and ports within the scope of compliance with Solas, IMO, Marpol, STCW,Colreg,IACS,Class rules, Dubai and DMCC legislations rules."
      />
      <Services/>
      <ServicesItem/>
      <LatestNews/>
      <HomeTwoSectionss/>
      <Footer/>
    </div>
  );
}

export default Company;
