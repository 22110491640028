export default function ContactWithLinksLatest({ category: {id, title,text,textbuton} }) {
    return (
            <a href="#" className="flex flex-col p-3 transition border rounded items-left gap-y-4 hover:bg-gray-50">
            <span className="p-0 m-0 text-lg font-semibold tracking-tight text-gray-900 whitespace-nowrap ">{title}</span>
            <span className="p-0 m-0 text-lg tracking-tight text-left textColor">{text}</span>
            <span className="p-0 m-0 text-lg tracking-tight text-left textColor">
            <button class="bg-gray-200 hover:bg-gray-300 text-gray-600 font-semibold text-sm py-2 px-4 rounded inline-flex items-center">
            <span>{textbuton}</span>
            </button>
            </span>
            </a>
    )
}