import Footermenu from './ui/Footermenu'
export default function Footer() {
  const menus =[
    {
      title:"Discover",
      items:[
        {
          title:"About us"
        },
        {
          title:"Services"
        },
        {
          title:"Sustainability",
          link:"/Sustainability"
        },
        {
          title:"Careers"
        }
      ]
    },
    {
      title:"Learn More",
      items:[
        {
          title:"Contact us"
        },
        {
          title:"Whistleblower"
        },
        {
          title:"News"
        },
        {
          title:"Supplier Terms"
        }
      ]
    },
    {
      title:"Terms",
      items:[
        {
          title:"Disclaimer"
        },
        {
          title:"Terms and Conditions"
        },
        {
          title:"Privacy Policy"
        },
        {
          title:"Cookie Policy"
        }
      ]
    },
    {
      title:"Social Media",
      items:[
        {
          title:"Facebook"
        },
        {
          title:"Instagram"
        },
        {
          title:"Youtube"
        },
        {
          title:"Twitter"
        }
      ]
    }
  ]
    return (
      <div className="bg-gray-100">
          <div className="container mx-auto">
      <div className="grid grid-cols-1 pt-20 pl-5 lg:pl-0 md:pl-0 lg:gap-6 md:grid-cols-2 lg:grid-cols-4 md:gap-y-20 sm:grid-cols-1 gap-y-12 ">
      {menus.map((menu,index,link)=><Footermenu key={index} href={link} {...menu}/>)}         
      </div>
      <div className="flex items-center pt-10 pb-4 pl-5 mt-16 border-t md:pl-0 md:justify-between border-gray-150">
        <a href="/" className="text-sm textColor hover:text-gray-400 hover:underline">&copy; 2021 Densay</a>
      </div>
          </div>
      </div>
    )
}
