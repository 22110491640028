import React from 'react';
import Header from 'components/Header'
import HeroSection from 'components/HeroSection'
import Footer from 'components/Footer';
import HeaderComp from 'components/ui/HeaderComp';
import FreightItem from 'components/FreightItem';
import JustHeaderH6 from 'components/ui/JustHeaderH6'
import Cargoes from 'components/pages/Cargoes'
function FreightServices() {
  return (
    <div className='home'>
      <Header/>
      <HeroSection/>
      <HeaderComp
      SectionHeader="Freight Services"
      text1="We offer our customers efficient and reliable freight services. We carry a wide range of cargoes such as dry bulk, breakbulk, oil products, and easy chemicals with a modern and environmentally friendly fleet. "
      text2="We employ a one-stop-shop approach through a combination of voyage charters, time charters and freight logistics. We can readily provide a suitable vessel for your trade and 
      cargo. We cover all size segments and geographical areas, 24 hours a day, seven days a week."
      />
      <JustHeaderH6
      SectionHeader="What we do"
      />
      <FreightItem/>
      <Cargoes/>
      <Footer/>
    </div>
  );
}

export default FreightServices;
