import React from 'react';
import ContactComp from 'components/ui/ContactComp'
import ContactMap from 'components/ui/ContactMap'
function CcompandMap() {
  return (
    <div className="flex flex-row container mx-auto mt-20 mb-14">
      <ContactComp/>
      <ContactMap/>
    </div>
  );
}
export default CcompandMap;
