import LatestNewsButton from "./LatestNewsButton"
import JustHeaderH6 from "./ui/JustHeaderH6"
import LatestItem from "./LatestItem"
export default function LatestNews() {
    return (
        <div className="container p-5 mx-auto md:p-0 lg:p-0 sm:p-0 lg:pt-10">
             <JustHeaderH6
      SectionHeader="Latest News"
      />
            <LatestNewsButton/>
            <LatestItem/>
        </div>
    )
}
