import React from 'react';
import Navbar from "./Navbar"
import NavbarMenu from 'components/NavbarMenu'
export default function Header() {
    return (
        <div className="pt-3 pb-3 pl-0 pr-0 ">
                    <div className="container flex items-center justify-between pl-5 pr-5 mx-auto 2xl:pl-0 xl:pl-0 md:pl-0">
                        <a href="/" className=""><img src="./images/logo.fw.png" alt=""  className="w-full"/></a>
                        <NavbarMenu/>
                        <Navbar 
                        />
                </div>
        </div>
    )
}
