 function TwoSectionItem({ category: {id,text,title,imgUrl} }) {
    return (
        <div className="container flex flex-wrap py-0 py-2 pb-12 mx-auto lg:py-12 ">
            <div className="pt-0 left-1 flex-2 lg:flex-2 xl:max-w-4xl 2xl:max-w-5xl xl:pr-12 md:flex-2 2xl:pr-4 "> 
            <h1 className="text-2xl font-semibold lg:text-3xl lg:font-normal ">{title}</h1>
            <p className="py-4 text-left textColor text-md lg:text-lg">{text}</p>
            </div>
            <div className="flex-1 pl-0 lg:flex-1 2xl:pl-6 xl:pl-0 " >
                <img src={imgUrl} alt="" className="w-full"/>
            </div>
        </div>
    )
}
export default TwoSectionItem;