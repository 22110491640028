import React from 'react';
import Header from 'components/Header'
import HeroSection from 'components/HeroSection'
import Footer from 'components/Footer';
import HeaderComp from 'components/ui/HeaderComp';
import IntegratedComp from 'components/ui/IntegratedComp'
function Integrated() {
  return (
    <div className='home'>
      <Header/>
      <HeroSection/>
      <IntegratedComp className="text-center "
      SectionHeader="5.1 INTEGRATED MANAGEMENT SYSTEM POLICY"
      />
      <div className="pb-16 ">
      <IntegratedComp
      SectionHeader="Customer focused"
      text1="Operating in the national and international maritime sector, Densay, with its reliable identity, focuses on providing an efficient ship management and uninterrupted service that will meet the cargo responsibility above customer expectations in all its operations."
      text2="It sees the feedback from customers as an opportunity for improvement and selects all interested parties that come into contact with the customer in line with the principle of customer-oriented approach."
      />
      <IntegratedComp
      SectionHeader="Safety and Environmental Orientation"
      text1="To protect natural resources by reducing sea, air and water pollution that may affect the safety of life, property and port facilities while performing ship operations, and that may result from the use of products and services,"
      text2="Contributing to the prevention of climate changes, by monitoring the carbon emissions that may arise from ship logistics activities, taking steps towards zeroing greenhouse gases,"
      text3="To plan with the philosophy of life cycle in the process from supply chain management to the delivery of products and services, to make purchases that increase the life cycle in purchasing activities and to constantly improve their conditions,"
      />
      <IntegratedComp
      SectionHeader="Employee Orientation"
      text1="To create an OHS and environmental corporate culture in its employees with a proactive perspective by planning all processes that carry out ship operations with a risk-oriented approach and managing risks effectively,"
      text2="To train all employees within the integrated management system in land and sea operations and to develop them with safe behavior models,"
      text3="While providing all necessary resources to improve the performance of the integrated management system; To encourage the employees by keeping all communication channels open to receive their opinions and suggestions, and to ensure their greater participation in the system,"
      text4="To target “0” accidents and to prevent the occurrence of occupational diseases, to provide the necessary resources in accordance with technological developments and to improve by following the changes,  "
      text5="To exhibit a communication-oriented working model in all its activities with its employees, employee representatives and other employees with whom it interacts, to make safe technical infrastructure and environmentalist choices in its work, to question compliance with all legal and other requirements and to work continuously. to develop with innovation studies,"
      />
      <IntegratedComp
      SectionHeader="Sustainability"
      text1="Densay; making plans to achieve strategic goals; It has added to its goals to go out of the individual economic cycle and to stand out with holistic ethical values and social responsibility projects."
      text2="It not only ensures the fulfillment of international standards and legal requirements such as health, environment and safety, but also aims to follow a carbon-free business model by operating green ships and to ensure social welfare by choosing ship workers from women."
      text3="While securing its experience and knowledge with integrated management systems, it undertakes to continuously develop and improve the system with its entire team."
      />
      <IntegratedComp
       text1="01.11.2021"
       text2="Rev:0"
      />
      </div>
      <Footer/>
    </div>
  );
}

export default Integrated;
