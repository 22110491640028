import React from 'react';
import Header from 'components/Header'
import HeroSection from 'components/HeroSection'
import Footer from 'components/Footer';
import CcompandMap from 'components/ui/CcompandMap'
import HeaderComp from 'components/ui/HeaderComp';
import ContactWithLinks from 'components/ContactWithLinks'
function Contact() {
  return (
    <div className='home'>
      <Header/>
      <HeroSection/>
      <HeaderComp
      SectionHeader="Contact Us"
      text1="Diverse backgrounds, a single culture. At Densay, our most valuable asset is our people. We are an inclusive group, always interested in hiring like-minded individuals who will share our values."
      text2="We offer a wide range of positions in our freight services, ship management, asset management, finance, and administrative departments. We also offer masters, officers, engineers, electricians, ratings, and cadets positions onboard our managed vessels. We pride ourselves in providing a safe, diverse, and pleasant work environment both onshore and onboard."
      />
      <CcompandMap/>
      <ContactWithLinks/>
      <Footer/>
    </div>
  );
}

export default Contact;
