import React from 'react';
import Header from 'components/Header'
import HeroSection from 'components/HeroSection'
import CareersItem from 'components/CareersItem'
import Footer from 'components/Footer';
import HeaderComp from 'components/ui/HeaderComp';
import CareerOneSection from 'components/CareerOneSection'
import JustHeaderH6 from 'components/ui/JustHeaderH6';
import CareersWithLinks from 'components/CareersWithLinks';
import CareersWithLinksLast from 'components/CareersWithLinksLast';
function Careers() {
  return (
    <div className='home'>
      <Header/>
      <HeroSection/>
      <HeaderComp
      SectionHeader="Careers"
      text1="Diverse backgrounds, a single culture. At Densay, our most valuable asset is our people. We are an inclusive group, always interested in hiring like-minded individuals who will share our values."
      text2="We offer a wide range of positions in our freight services, ship management, asset management, finance, and administrative departments. We also offer masters, officers, engineers, electricians, ratings, and cadets positions onboard our managed vessels. We pride ourselves in providing a safe, diverse, and pleasant work environment both onshore and onboard."
      />
      <CareersItem/>
      <CareerOneSection/>
      <JustHeaderH6
         SectionHeader="Open Positions"
      />
      <CareersWithLinks/>
      <JustHeaderH6
         SectionHeader="Unsolicited Applications"
      />
      <CareersWithLinksLast/>
      <Footer/>
    </div>
  );
}

export default Careers;
