import JustHeaderH6 from "./ui/JustHeaderH6"
export default function Services() {
    return (
        <div className="container mt-8 p-5 mx-auto  md:p-0 lg:p-0 sm:p-0 xs:p-0">
        <JustHeaderH6
       SectionHeader="Services"
       />
      </div>
    )
}
