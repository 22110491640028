import React from 'react';
import Header from 'components/Header'
import HeroSection from 'components/HeroSection'
import Services from 'components/Services'
import ServicesItem from 'components/ServicesItem'
import LatestNews from 'components/LatestNews'
import Footer from 'components/Footer'
import HomeTwoSectionss from 'components/HomeTwoSectionss'
import HeaderComp from 'components/ui/HeaderComp'
function Home() {
  return (
    <div className='home'>
      <Header/>
      <HeroSection/>
      <HeaderComp
      SectionHeader="About us"
      text1="Densay is an integrated shipping group that plays a crucial role in the global commodity supply chain. We leverage our experience, data, and intelligence to deliver reliable, safe, and efficient maritime solutions to our customers in an increasingly data-driven and digitized world."
      text2="We transport a wide range of commodities, serving grain houses, energy companies, traders, miners, steel mills, industrials, and utility providers. We manage ships that carry essential commodities across the oceans, helping bring food from farm to refrigerator. We enable global trade by investing in supply chains and crucial assets."
      text3="Global trade drives economic growth and development. Our mission is to help facilitate global trade. Our vision is to transform it through innovation and digitization, making it more efficient, responsible, and sustainable."
      />
      <Services/>
      <ServicesItem/>
      <LatestNews/>
      <HomeTwoSectionss/>
      <Footer/>
    </div>
  );
}

export default Home;
