import React from 'react';
import Header from 'components/Header';
import HeroSection from 'components/HeroSection';
import Footer from 'components/Footer';
import ShipManagementCard from 'components/ShipManagementCard';
import HeaderComp from 'components/ui/HeaderComp';
import JustHeaderH6 from 'components/ui/JustHeaderH6';
import AssetOneSection from 'components/ShipmanagementTwoSection';
function Shipmanagement() {
  return (
    <div className='home'>
      <Header/>
      <HeroSection/>
      <HeaderComp
      SectionHeader="Ship Management"
      text1="We provide safe and efficient ship management services such as technical management, crewing, and supervision. We manage our assets to the highest quality standards."
      text2="We manage a modern fleet of bulk carriers and product tankers, combining years' worth of experience with modern technological capabilities, use of data, and automation."
      />
      <JustHeaderH6
      SectionHeader="What we do"
      />
      <ShipManagementCard/>
      <AssetOneSection/>
      <Footer/>
    </div>
  );
}
export default Shipmanagement;
