import React from 'react'

export default function Footermenu({title,items}) {
    return (
        <section className="footer-ul">
      <nav className="grid gap-y-6 ">
      <h6 className="font-semibold text-md textHeader">{title}</h6>
      <nav>
          <ul className="grid gap-y-2 ">
            {items.map((item,key)=>(
              <li key={key} >
              <a href="/" className="text-md hover:text-gray-500">
                  {item.title}
              </a>
          </li>
          ))}
          </ul>
      </nav>
        </nav>
        </section>
    )
}
