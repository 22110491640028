import React from 'react';

const JustHeaderH6 =(props)=>{
    return(
        <div className="container mb-0 sm:mb-0 lg:mb-0 xl:mb-4 2xl:mb-4 mx-auto md:p-0 lg:mt-14 sm:p-0 lg:pb-2 ">
        <h1 className="text-2xl font-semibold lg:font-medium lg:text-3xl ">{props.SectionHeader}</h1>
      </div>
    );
}

export default JustHeaderH6;