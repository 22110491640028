import React from 'react';
import Header from 'components/Header';
import HeroSection from 'components/HeroSection';
import Footer from 'components/Footer';
import SustainabilityCard from 'components/SustainabilityCard';
import JustHeaderH6 from 'components/ui/JustHeaderH6';
import SustainabilitySection from 'components/SustainabilitySection';
import HeaderComp from 'components/ui/HeaderComp';
import SustainabilityWithLink from 'components/SustainabilityWithLink';
function Sustainability() {
  return (
    <div className='home'>
      <Header/>
      <HeroSection/>
      <HeaderComp
      SectionHeader="Sustainability"
      text1="Ocean transportation is the most efficient and environmentally-friendly method of moving cargo over long distances. However, we still continuously work towards minimizing our environmental impact and positively improve our community. Our approach to sustainability is closely aligned with the UN Sustainable Development Goals."
      text2="After extensively assessing each relevant SDG, we have identified the most relevant ones to our business. We have decided to focus on SDG 3 (Good health and well-being), SDG 5 (Gender equality), SDG 8 (Decent work and economic growth), SDG 10 (Reduced inequalities), SDG 9 (Industry, innovation, and infrastructure), SDG 13 (Climate change), SDG 14 (Life below water), SDG 15 (Life on land), and SDG (Peace, justice and strong institutions)."
      />
      <JustHeaderH6
      SectionHeader="What we do"
      />
      <SustainabilityCard/>
      <SustainabilitySection/>
      <SustainabilityWithLink/>
      <Footer/>
    </div>
  );
}

export default Sustainability;
