export default function Category({ category: {id, title, image,text,link} }) {
    return (
           <div className="container p-0 border rounded">
            <a href={link} className="flex flex-col items-left ">
            <img src={image} alt={title} href={link} className="border border-gray-200 " />
            <span className="pl-2 m-0 mt-6 mb-2 text-lg font-semibold tracking-tight textHeader whitespace-nowrap ">{title}</span>
            <span className="pb-4 pl-2 pr-2 m-0 mt-2 mb-2 tracking-tight text-left textColor text-md">{text}</span>
            </a>
           </div>
    )
}