import Slider from "react-slick";

export default function HeroSection() {

    const settings = {
        dots: false,
        infinite: true,
        arrows:false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
    return (
        <div className="z-0">
            <Slider {...settings}>
          <div className="mx-auto bg-gray-500">
                <img src="images/companybannerr.jpg" alt="" className="w-12/12"  />
          </div>
        </Slider>
        </div>
    )
}
