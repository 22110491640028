export default function HeaderComp(props) {
    return (
          <div className="container p-5 mx-auto mt-14 md:p-0 lg:p-0 sm:p-0 ">
            <h1 className="text-2xl font-semibold lg:font-medium lg:text-3xl lg:pb-1">{props.SectionHeader}</h1>
            <p className="max-w-5xl pr-0 mt-2 text-left text-md lg:text-lg md:max-w-5xl xl:max-w-4xl 2xl:max-w-5xl textColor">{props.text1}</p>
            <p className="max-w-5xl pr-0 mt-4 text-left text-md lg:text-lg md:max-w-5xl xl:max-w-4xl 2xl:max-w-5xl textColor">{props.text2}</p>
            <p className="max-w-5xl pr-0 mt-4 text-left text-md lg:text-lg md:max-w-5xl xl:max-w-4xl 2xl:max-w-5xl textColor">{props.text3}</p>
          </div>
    )
}
