import React from 'react';
import CargoesCard from 'components/CargoesCard'
import JustHeaderH6 from 'components/ui/JustHeaderH6';
import CargoesTwoSectionss from 'components/CargoesTwoSectionss';
function Cargoes() {
  return (
    <div className='home'>
      <JustHeaderH6
         SectionHeader="Cargoes"
      />
      <CargoesCard/>
      <CargoesTwoSectionss/>
    </div>
  );
}

export default Cargoes;
