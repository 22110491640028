import JustHeaderH6 from "./JustHeaderH6"
export default function ContactComp() {
    return (
          <div className="container p-5 my-auto mt-auto md:p-0 lg:p-0 sm:p-0 mr-5 col-start-2">
      <JustHeaderH6
      SectionHeader="Head Office "
      />
      <p className="max-w-5xl pr-0 mt-4 text-left text-md lg:text-lg md:max-w-5xl xl:max-w-4xl 2xl:max-w-5xl textColor">
      <span className="font-semibold">Address</span>
      <p className="mb-6">HDS Tower, Unit 1602
      Jumeirah Lakes Towers
      Dubai, United Arab Emirates</p> 
      <span className="font-semibold">Phone</span>
      <p className="mb-6">+971 4 368 72 18</p> 
      <span className="font-semibold">E-Mail</span>
      <p className="mb-6">info@densay.com</p> 
      </p>
      </div>
    )
}
