import { useState, useEffect } from 'react';
import categoryData from 'api/SustainabilityCategories.json'
import Category from 'components/ui/SustainabilityCategory'
export default function Categories() {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        // istek
        // setTimeout(() => setCategories(categoryData), 1000)
        setCategories(categoryData)
    }, [])
    return (
        <div className="p-5 py-4 mx-auto bg-white md:p-0 lg:p-0 sm:p-0">
            <div className="container py-4 mx-auto ">
                <div className="grid gap-8 md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-1 sm:gap-y-16">
                    {/* {!categories.length && 'Yükleniyor..'} */}
                    {categories && categories.map((category, index) => <Category key={index} category={category} />)}
                </div>
            </div>
        </div>
    )
}